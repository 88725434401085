import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'footer',
    templateUrl: './footer.html',
})
export class FooterComponent {
    currYear: number = new Date().getFullYear();
    showFooter: boolean = true;
    constructor( public router: Router,) {}
    ngOnInit() {
        this.router.events.subscribe(() => {
          // Aquí puedes controlar las rutas donde deseas ocultar el footer
          const url = this.router.url;
          this.showFooter = !['/app/carreras'].includes(url);
        });
      }
}
