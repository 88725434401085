import { Pipe, PipeTransform } from "@angular/core";

import * as moment from 'moment-timezone';

@Pipe({
  
  name: "dateTimeFormat",
  pure: false
})
export class DateTimeFormatPipe implements PipeTransform {
  
  
  transform(date: string, format: string): string {
    if(date === null){
      return '';
    }else{
      moment.locale('es-ES')
      return moment(date).tz('America/Guayaquil').format(format);
    }
  }
}