import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule } from '@angular/router';

//Routes
import { routes } from './app.route';

import { AppComponent } from './app.component';

// service
import { AppService } from './service/app.service';

// store
import { StoreModule } from '@ngrx/store';
import { indexReducer } from './store/index.reducer';

// i18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// headlessui
import { MenuModule } from 'headlessui-angular';

// perfect-scrollbar
import { NgScrollbarModule } from 'ngx-scrollbar';

// dashboard
import { IndexComponent } from './index';

// Layouts
import { AppLayout } from './layouts/app-layout';

import { HeaderComponent } from './layouts/header';
import { FooterComponent } from './layouts/footer';
import { SidebarComponent } from './layouts/sidebar';
import { ThemeCustomizerComponent } from './layouts/theme-customizer';
import { IconModule } from './shared/icon/icon.module';
import { HttpRequestLoggerInterceptor } from './interceptors/http-request-logger.interceptor';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CarrerasComponent } from './features/carreras/carreras.component';
import { JwtService } from './service/jwt.service';
import { ClientesService } from './features/gestion/clientes/services/clientes.service';
import { DataTableModule } from '@bhplugin/ng-datatable';
import { SharedModule } from "./shared/shared.module";
import { CarrerasService } from './features/carreras/services/carreras.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { UnidadesService } from './features/gestion/unidades/services/unidades.service';
import { NgxTippyModule } from 'ngx-tippy-wrapper';


@NgModule({
    imports: [
    RouterModule.forRoot(routes, { useHash: true,
        preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' }),
    BrowserModule,
    DataTableModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MenuModule,
    NgApexchartsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient],
        },
    }),
    StoreModule.forRoot({ index: indexReducer }),
    NgScrollbarModule.withConfig({
        visibility: 'hover',
        appearance: 'standard',
    }),
    IconModule,
    SharedModule,
    NgSelectModule,
    NgxTippyModule
],
    
    declarations: [DashboardComponent, CarrerasComponent, AppComponent, HeaderComponent, FooterComponent, SidebarComponent, ThemeCustomizerComponent, IndexComponent, AppLayout],
    providers: [
        AppService, 
        CarrerasService,
        ClientesService,
        UnidadesService,
        JwtService,
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestLoggerInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
   
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
