
<div class="w-full h-full">
    <div class="grid grid-cols-1 gap-4 h-full sm:grid-cols-2 lg:grid-cols-2">
      <div class="flex flex-col w-full sm:col-span-1 overflow-hidden">
        <div class="panel datatable h-[450px] mb-3 overflow-y-auto relative">
          <ng-datatable
            [rows]="clientes"
            [columns]="cols"
            [pageSize]="10"
            sortDirection="desc"
            [pageSizeOptions]="[5,10,15,20]"
            [loading]="loadingClientes"
            [columnFilter]="true"
            rowClass="cursor-pointer"
            (rowClick)="addCarrera($event)"
            noDataContent="No se encontraron registros coincidentes"
            paginationInfo="{0} al {1} de {2}"
            skin="bh-table-hover"
            class="w-full h-full"
          >
            <ng-template slot="codigocliente" let-value="data">
              <div class="flex items-center font-semibold text-[15px] justify-center">
                <div class="whitespace-wrap">
                  <strong>{{value.codigocliente}}</strong>
                </div>
              </div>
            </ng-template>
            <ng-template slot="nombre_persona" let-value="data">
              <div class="whitespace-wrap text-[12px] justify-center">
                {{value.nombre_persona}}
              </div>
            </ng-template>
            <ng-template slot="telefonocelular" let-value="data">
              <div class="flex items-center font-semibold justify-center text-[12px]">
                <div class="flex flex-col">
                  <p class="whitespace-wrap">{{value.telefonocelular}}</p>
                  <p class="whitespace-wrap">{{value.telefonofijo}}</p>
                </div>
              </div>
            </ng-template>
            <ng-template slot="direccion" let-value="data">
              <div class="whitespace-wrap text-[11px] justify-center">
                {{value.direccion}}
              </div>
            </ng-template>
            <ng-template slot="ubicacionexacta" let-value="data">
              <div class="whitespace-wrap text-[11px] justify-center">
                {{value.ubicacionexacta}}
              </div>
            </ng-template>
          </ng-datatable>
        </div>
        
      
        <div class="panel w-full sm:col-span-1 relative py-1 p-2">
          <a ngxTippy="No cliente" class="cursor-pointer">
            <div class="absolute text-xs text-white-light top-0 left-0 h-full w-[60px] bg-primary flex items-center justify-center">
              NC
            </div>
          </a>
          <form class="ml-[60px]">
            <div class="grid grid-cols-1 sm:grid-cols-4 gap-3 justify-around">
              <div class="justify-self-start">
                <label for="nombres" class="after:content-['*'] after:ml-0.5 after:text-red-500">Nombres</label>
                <input id="nombres" type="text" placeholder="Nombres" class="form-input invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" />
              </div>
              <div class="justify-self-center">
                <label for="telefonocelular" class="after:content-['*'] after:ml-0.5 after:text-red-500">Teléfono celular</label>
                <input id="telefonocelular" type="text" placeholder="Teléfono celular" class="form-input invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" />
              </div>
              <div class="sm:col-span-2 justify-self-start">
                <label for="direccionpartida" class="after:content-['*'] after:ml-0.5 after:text-red-500">D. de partida</label>
                <div class="flex">
                  <input id="direccionpartida" type="text" placeholder="Dirección de partida" class="form-input ltr:rounded-r-none rtl:rounded-l-none invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" />
                  <button type="button" class="btn btn-primary ltr:rounded-l-none rtl:rounded-r-none">Crear</button>
                </div>
              </div>
            </div>
          </form>
        </div>
     
      </div>
      
      <div class="panel w-full h-[500px] sm:col-span-1 lg:h-[525px] overflow-y-auto relative">
        <div class="table-responsive" *ngIf="!loadingCarreras">
          <div class="contenedor-tabla">
            <table class="text-[12px] table-hover w-full table-striped relative" >
              <div class="sticky top-0 bg-white z-10 select-none">
                <thead class="w-full">
                  <tr>
                    <th colspan="3" class="text-base font-bold text-left">{{carreras.length}}  carreras activas</th>
                    <th colspan="3" class="text-base font-bold">
                      <div class="flex justify-end">
                        <div class="ltr:mr-4 rtl:ml-4">
                            <button (click)="finishCarrerasAll()" type="button" ngxTippy="Finalizar todas las carreras con unidades asignadas." class="hover:text-primary">
                                <icon-checks />
                            </button>
                        </div>
                        <div>
                            <button (click)="obtenerCarreras()" type="button" ngxTippy="Recargar carreras." class="hover:text-primary">
                                <icon-refresh class="h-6 w-6" />
                            </button>
                        </div>
                    </div>
                    </th>
                  </tr>
                  <tr >
                    <th class="font-bold text-[13px]" style="width: 40px;">Bot</th>
                    <th class="font-bold text-[13px]" style="width: 100px;">Cód. cliente</th>
                    <th class="font-bold text-[13px]" style="width: 200px;">Cliente</th>
                    <th class="font-bold text-[13px]" style="width: 150px;">Unidad</th>
                    <th class="font-bold text-[13px]" style="width: 80px;">Fecha</th>
                    <th class="font-bold text-[13px]" style="width: 100px;">Opciones</th>
                  </tr>
                </thead>
              </div>
              <tbody>
                <ng-container *ngFor="let carrera of carreras;">
                  <div [ngClass]="{'border-l-4 border-l-warning': carrera.unidadid === 0 && carrera.estadocarrera === 'O'}">
                      
                  <tr>
                    <td class="whitespace-wrap font-bold text-[13px] content-center" style="width: 40px;">
                      <a class="hover:text-gray-500 text-primary cursor-pointer" ngxTippy="Carrera creada por el bot" *ngIf="carrera.boot">
                        <icon-chat-round class="shrink-0" />
                      </a>
                    </td>
                    <td class="whitespace-wrap font-bold text-[13px] content-center text-center" style="width: 100px;">{{ carrera.codigocliente || 'NC' }}</td>
                    <td class="font-semibold whitespace-wrap content-center text-left" style="width: 200px;">
                      <div class="flex flex-col">
                        <p *ngIf="carrera.nombrecliente != ''">{{ carrera.nombres || '' }} {{ carrera.apellidopaterno || '' }} {{ carrera.apellidomaterno || '' }}</p>
                        <p *ngIf="carrera.nombrecliente">{{ carrera.nombrecliente }}</p>
                        <p>{{ carrera.telefonocelular || carrera.telefonocliente || carrera.telefonofijo || '' }}</p>
                        <p>{{ carrera.nombrebase || '' }}</p>
                      </div>
                    </td>
                    <td style="width: 150px;" class="content-center">
                      <ng-select class="text-[12px] custom-multiselect" [searchable]="true" [(ngModel)]="carrera.unidadid" labelForId="unidadid" [items]="unidades" bindValue="id" bindLabel="numerounidad" [loading]="loadingUnidades" (change)="onChangeUnidad($event, carrera)">
                        <ng-template ng-label-tmp let-item="item">Unidad # {{ item.numerounidad }}</ng-template>
                        <ng-template ng-option-tmp let-item="item"><b>Unidad # {{ item.numerounidad }}</b> <br /></ng-template>
                      </ng-select>
                    </td>
                    <td class="whitespace-wrap content-center" style="width: 80px;">{{ carrera.fecharegistro | dateTimeFormat:'HH:mm:ss' }}</td>
                    <td class="whitespace-wrap content-center" style="width: 100px;">
                      <div class="flex flex-row">
                        <ng-container *ngIf="carrera.unidadid != 0 && carrera.estadocarrera === 'O'">
                          <a class="hover:text-gray-500 text-primary cursor-pointer" ngxTippy="Finalizar carrera">
                            <icon-square-check class="shrink-0" />
                          </a>
                        </ng-container>
                        <a class="hover:text-gray-500 text-danger cursor-pointer ml-1" ngxTippy="Cancelar carrera"  (click)="cancelarCarrera(carrera)">
                          <icon-trash class="shrink-0" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr class="whitespace-wrap border-t-0">
                    <td colspan="6" class="whitespace-wrap text-[11px]" *ngIf="carrera.direccion || carrera.direccionpartida || carrera.ubicacionexacta || carrera.observacionpersona">
                      <ng-container *ngIf="carrera.direccion || carrera.direccionpartida">
                        <strong>Dirección:</strong> {{ carrera.direccion || carrera.direccionpartida }}<br />
                      </ng-container>
                      <ng-container *ngIf="carrera.ubicacionexacta">
                        <strong>Ubicación Exacta:</strong> {{ carrera.ubicacionexacta }}<br />
                      </ng-container>
                      <ng-container *ngIf="carrera.observacionpersona">
                        <strong>Observación:</strong> {{ carrera.observacionpersona }}
                      </ng-container>
                    </td>
                  </tr>
                  </div>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="loadingCarreras" class="flex mt-32">
          <span class="animate-spin border-4 border-success border-l-transparent rounded-full w-12 h-12 inline-block align-middle m-auto mb-10"></span>
        </div>
      </div>
      
    </div>
</div>

<style>
   :host ::ng-deep .bh-datatable .bh-table-responsive table th .bh-filter > button {
    display: none !important;
}

 /* :host ::ng-deep .bh-datatable .bh-pagination {
    display: none !important;
 } */

 /* Estilo para ocultar la barra de desplazamiento en la mayoría de los navegadores */
.panel::-webkit-scrollbar {
  width: 0px; /* Para el scrollbar vertical */
  height: 0px; /* Para el scrollbar horizontal */
}

.panel {
  -ms-overflow-style: none;  /* IE y Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Alternativamente, si necesitas ocultar solo el scrollbar vertical */
.panel::-webkit-scrollbar:vertical {
  width: 0;
}

.panel::-webkit-scrollbar:horizontal {
  height: 0;
}

.contenedor-tabla {
  max-height: 480px;
  overflow: auto;
  -ms-overflow-style: none;  /* IE y Edge */
  scrollbar-width: none;  /* Firefox */
 
}

thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* th,
td {
  padding: 1rem;
} */

</style>
