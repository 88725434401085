import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFilterPipe } from './pipe/CustomFilterPipe.pipe';
import { DateTimeFormatPipe } from './pipe/date-time-format.pipe';

@NgModule({
  declarations: [DateTimeFormatPipe, CustomFilterPipe],
  imports: [CommonModule],
  exports: [DateTimeFormatPipe, CustomFilterPipe] // Exportar el pipe para que esté disponible en otros módulos
})
export class SharedModule {}