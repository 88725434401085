// jwt.service.ts
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
// const { jwtDecode } = require('jwt-decode');

@Injectable({
  providedIn: 'root'
})
export class JwtService {

    private tokenKey = 'auth_token_rtsg'; // Clave para el token
    decodedToken: any;

    constructor() { }
  
    storeToken(token: string): void {
      sessionStorage.setItem(this.tokenKey, token); // Almacenar el token en sessionStorage
    }
  
    getToken(): string | null {
      return sessionStorage.getItem(this.tokenKey); // Obtener el token de sessionStorage
    }
  
    removeToken(): void {
      sessionStorage.removeItem(this.tokenKey); // Eliminar el token de sessionStorage
    }
  
    decodeToken(token: any): any {
      try {
        return this.decodedToken = jwtDecode(token); // Decodificar el token JWT usando jwt-decode
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    }

    getDecodedToken(): any {
      return this.decodedToken;
    }
  
  }