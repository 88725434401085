import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppService } from '../service/app.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { JwtService } from '../service/jwt.service';
import { AuthService } from '../features/auth/login/services/auth.service';

@Component({
    moduleId: module.id,
    selector: 'header',
    templateUrl: './header.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class HeaderComponent {
    title_pagina!:string;
    store: any;
    search = false;
    usuarioLoged:any;
    

    constructor(
        public translate: TranslateService,
        public storeData: Store<any>,
        public router: Router,
        private route: ActivatedRoute,
        private appSetting: AppService,
        private jwtService: JwtService,
        private authService: AuthService,
        private sanitizer: DomSanitizer
    ) {
        this.initStore();
        this.usuarioLoged = this.jwtService.decodeToken(sessionStorage.getItem("auth_token_rtsg"));
        console.log(this.jwtService.decodeToken(sessionStorage.getItem("auth_token_rtsg")));
        
        
        
    }
    async initStore() {
        this.storeData
            .select((d) => d.index)
            .subscribe((d) => {
                this.store = d;
            });
            this.iniciarPathRoute();
    }

    ngOnInit() {
       
      }


    iniciarPathRoute(){
        this.setActiveDropdown();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setActiveDropdown();
                this.title_pagina = this.route.firstChild!.snapshot.data['title'];
            }
        });
    }

    logout(){
        this.authService.logout();
    }

    getFirstName(input: string): string {
        return input.split(' ')[0];
    }



    setActiveDropdown() {
        const selector = document.querySelector('ul.horizontal-menu a[routerLink="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (let i = 0; i < all.length; i++) {
                all[0]?.classList.remove('active');
            }
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    }


    changeLanguage(item: any) {
        this.translate.use(item.code);
        this.appSetting.toggleLanguage(item);
        if (this.store.locale?.toLowerCase() === 'ae') {
            this.storeData.dispatch({type: 'toggleRTL', payload: 'rtl'});
        } else {
            this.storeData.dispatch({type: 'toggleRTL', payload: 'ltr'});
        }
        window.location.reload();
    }
}
