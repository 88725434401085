import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CarreraRespuesta } from '../interfaces/interfaces.interface';
import { Carrera } from '../models/carrera.model';

@Injectable()
export class CarrerasService {

    url:string = ""
    constructor(private _http: HttpClient, public router: Router,) { 
      this.url = environment.apiUrl;
    }
    

      /* CARRERAS */

    getCarreras(data:any): Observable<CarreraRespuesta> {
        return this._http.get<CarreraRespuesta>(this.url + `/carreras/mostrar.carreras?usuarioid=${data.usuarioid}&rolid=${data.rolid}&unidadid=${data.unidadid}`);
    }

    addCarrera(data: Carrera): Observable<CarreraRespuesta> {
        return this._http.post<CarreraRespuesta>(this.url + '/carreras/crear.carrera', data);
    }

    updateCarrera(data: Carrera): Observable<CarreraRespuesta> {
        return this._http.put<CarreraRespuesta>(this.url + `/carreras/actualizar.carrera`, data);
    }

    finishCarreras(data:any): Observable<CarreraRespuesta> {
      return this._http.get<CarreraRespuesta>(this.url + `/carreras/finalizar.carreras.all?usuarioid=${data.usuarioid}`);
  }

}
