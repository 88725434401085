import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { AuthService } from 'src/app/features/auth/login/services/auth.service';



export const NoAuthGuard = (next: ActivatedRouteSnapshot) => {
   return inject(AuthService)
  .isLoggedIn() ? createUrlTreeFromSnapshot(next, ['/app']) : true
}