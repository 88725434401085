import { Routes } from '@angular/router';
import { AppLayout } from './layouts/app-layout';
import { IndexComponent } from '.';
import { AuthGuard } from './shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { CarrerasComponent } from './features/carreras/carreras.component';


const routes: Routes = [

  {
    path: 'app',
    data: { title: "RADIO TAXI SAN GREGORIO"},
    component: AppLayout,
    canActivate: [AuthGuard], 
    canActivateChild:[AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent,
        data: { title: "Estás en la sección dashboard"}
      },
      { path: 'carreras', component: CarrerasComponent,
        data: { title: "Estás en la sección carreras"}
      },
      
      // dashboard
      // { path: '**', redirectTo: 'clientes' },
     
      // { path: 'dashboard', component: IndexComponent },
      { path: 'clientes', loadChildren: () => import('./features/gestion/clientes/clientes.module').then((d) => d.ClientesModule), 
        data: { title: "Estás en la sección clientes"}
      },
      { path: 'socios', loadChildren: () => import('./features/gestion/socios/socios.module').then((d) => d.SociosModule), 
        data: { title: "Estás en la sección socios"}
      },
      { path: 'unidades', loadChildren: () => import('./features/gestion/unidades/unidades.module').then((d) => d.UnidadesModule), 
        data: { title: "Estás en la sección unidades"}
      },
      { path: 'monitores', loadChildren: () => import('./features/gestion/monitores/monitores.module').then((d) => d.MonitoresModule), 
        data: { title: "Estás en la sección monitores"}
      },
      { path: 'conductores', loadChildren: () => import('./features/gestion/conductores/conductores.module').then((d) => d.ConductoresModule), 
        data: { title: "Estás en la sección conductores"}
      },
      { path: 'asignaciones', loadChildren: () => import('./features/gestion/asignaciones/asignaciones.module').then((d) => d.AsignacionesModule), 
        data: { title: "Estás en la sección asignaciones"}
      },
      
      
      { path: 'personas', loadChildren: () => import('./features/configuracion/personas/personas.module').then((d) => d.PersonasModule), 
        data: { title: "Estás en la sección personas"}
      },
      { path: 'bases', loadChildren: () => import('./features/configuracion/bases/bases.module').then((d) => d.BasesModule), 
        data: { title: "Estás en la sección bases"}
      },
      { path: 'mi_empresa', loadChildren: () => import('./features/configuracion/empresa/empresa.module').then((d) => d.EmpresaModule), 
        data: { title: "Estás en la sección mi empresa"}
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard' } ,
      

      // Otros componentes y rutas de tu aplicación
    ]
  },
  {
    path: 'login',
    canActivate: [NoAuthGuard], 
    loadChildren: () => import('./features/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    redirectTo: 'login', 
    pathMatch: 'full' // Usar 'pathMatch: full' para asegurar coincidencia exacta
  },
  { 
    path: '**', 
    redirectTo: 'login' 
  },
  // Otras rutas de tu aplicación
];

export { routes };
