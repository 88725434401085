import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(private config: NgSelectConfig, private router: Router) {
        // Configuración global de NgSelect
        this.config.notFoundText = 'Elemento no encontrado';
        this.config.appendTo = 'body';
        this.config.bindValue = 'value';
        
    }

  
}
