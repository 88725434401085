import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UnidadRespuesta } from '../interfaces/interfaces.interface';
import { Unidad } from '../models/unidad.model';


@Injectable()
export class UnidadesService {

    url:string = ""
  clientes: any;
    constructor(private _http: HttpClient, public router: Router,) { 
      this.url = environment.apiUrl;
    }
    

   /* UNIDADES */

  getUnidades(): Observable<UnidadRespuesta> {
      return this._http.get<UnidadRespuesta>(this.url + `/unidades/mostrar.unidades`);
  }

  addUnidad(data: Unidad): Observable<UnidadRespuesta> {
      return this._http.post<UnidadRespuesta>(this.url + '/unidades/crear.unidad', data);
  }
  updateUnidad(data: Unidad): Observable<UnidadRespuesta> {
      return this._http.put<UnidadRespuesta>(this.url + `/unidades/actualizar.unidad`, data);
  }

}
