import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { animate, style, transition, trigger } from '@angular/animations';
import { JwtService } from 'src/app/service/jwt.service';
import { ClientesService } from '../gestion/clientes/services/clientes.service';
import { CarrerasService } from './services/carreras.service';
import { UnidadesService } from '../gestion/unidades/services/unidades.service';
import { AppService } from 'src/app/service/app.service';

@Component({
    moduleId: module.id,
    templateUrl: './carreras.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})

export class CarrerasComponent {

   
   searchTerm = '';
   usuarioLoged:any;
   store:any;
   clientes: any [] = []
   carreras: any [] = []
   unidades: any [] = []
   loadingClientes:boolean = true;
   loadingCarreras:boolean = true;
   loadingUnidades:boolean = true;
   cols = [
    { field: 'codigocliente', title: 'Cod.', isUnique: true, filter: true, width: '2px' },
    { field: 'nombre_persona', title: 'Cliente' },
    { field: 'telefonocelular', title: 'Teléfonos' },
    { field: 'direccion', title: 'Dirección' },
    { field: 'ubicacionexacta', title:'Ubicación E.'}
    // { field: 'email', title: 'Email' },
    // { field: 'age', title: 'Age', type: 'number' },
    // { field: 'dob', title: 'Birthdate', type: 'date' },
    // { field: 'isActive', title: 'Active', type: 'bool' },
];
    constructor(
        public storeData: Store<any>,
        private jwtService: JwtService,
        private _UnidadesService: UnidadesService,
        private _AppService: AppService,
        private _CarrerasService: CarrerasService,
        private _ClientesService: ClientesService,
    ) {
        this.usuarioLoged = this.jwtService.decodeToken(sessionStorage.getItem("auth_token_rtsg"));
        this.initStore();
        this.obtenerClientes();
        this.obtenerUnidades();
        this.obtenerCarreras();
    }

    async initStore() {
        this.storeData
            .select((d) => d.index)
            .subscribe((d) => {
                this.store = d;
                });
    }

    ngOnInit(){
        if (this.store.menu === "vertical" || (window.innerWidth <= 1024 && this.store.sidebarOpen)) {
            this.storeData.dispatch({type: 'toggleSidebar'});
        }
    }

    async obtenerClientes(){
        await this._ClientesService.getClientes().subscribe({        
              next: (data:any) => {
                if (data.estado) {this.clientes = data.datos}    
              },
             error: (error:any) => {
                  if (!error.ok) {
                      this.clientes = [], 
                      this.loadingClientes = false;
                  }
              },
              complete:() => {
                  this.loadingClientes = false;
              }
          }
        ) 
    }

    async obtenerCarreras(){
        this.loadingCarreras = true
        const data = {
            usuarioid: this.usuarioLoged.idusuario,
            rolid: this.usuarioLoged.rolid,
            unidadid: -1,
        }
        await this._CarrerasService.getCarreras(data).subscribe({        
              next: (data:any) => {
                if (data.estado) {this.carreras = data.datos}    
              },
             error: (error:any) => {
                  if (!error.ok) {
                      this.carreras = [], 
                      this.loadingCarreras = false;
                  }
              },
              complete:() => {
                  this.loadingCarreras = false;
              }
          }
        ) 
    }

    async obtenerUnidades(){
        await this._UnidadesService.getUnidades().subscribe({        
              next: (data:any) => {
                if (data.estado) {
                    this.unidades = data.datos.map((p: any) => {
                        return p.id === 0 ? { ...p, disabled: true } : p;
                    });
                }    
              },
             error: (error:any) => {
                  if (!error.ok) {
                      this.unidades = [], 
                      this.loadingUnidades = false;
                  }
              },
              complete:() => {
                  this.loadingUnidades = false;
              }
          }
        ) 
    }

    
    async finishCarrerasAll(){
        const dataFinish = {
            usuarioid: this.usuarioLoged.idusuario
        }
        await this._CarrerasService.finishCarreras(dataFinish).subscribe({        
              next: (data:any) => {
                console.log(data)
                if (data.estado) {}    
              },
             error: (error:any) => {
                  if (!error.ok) {
                      
                      this.loadingCarreras = false;
                  }
              },
              complete:() => {
                  this.loadingCarreras = false;
              }
          }
        ) 
    }

   async cancelarCarrera(carrera:any){
        await this._CarrerasService.updateCarrera(this.getDatosCarreraCancelado(carrera)).subscribe({        
            next: (data:any) => {
              if (data.estado) {
                this.carreras.filter((p:any) => Number(p.id) !== Number(data.datos.id));
                this.carreras = [...this.carreras];
                this._AppService.showNotificacionCarrera(`La cancelación de la carrera se ha realizado correctamente..`);
              }    
            },
           error: (error:any) => {
                if (!error.ok) {
                    this.loadingCarreras = false;
                }
            },
            complete:() => {
                this.loadingCarreras = false;
            }
        }
      )  
    }

    getDatosCarreraCancelado(carrera:any): any {
        return {
          id: carrera.id,
          clienteid: carrera.clienteid,
          unidadid: carrera.unidadid.toString(),
          nombrecliente: carrera.nombrecliente,
          direcciondestino: carrera.direcciondestino,
          direccionpartida: carrera.direccionpartida,
          observacion: carrera.observacion,
          estadocarrera: 'C',
          usuarioid: carrera.usuarioid,
          ubicacionexactacliente: carrera.ubicacionexactacliente,
          telefonocliente: carrera.telefonocliente,
          boot: carrera.boot ? 'true' : 'false'
        //   estado: data.estado,
        //  id: this.asignacion.id,
       };
     }

   async onChangeUnidad($event:any, carrera:any) {
		this.loadingCarreras = true;
        await this._CarrerasService.updateCarrera(this.getDatosCarreraOcupado(carrera, $event)).subscribe({        
            next: (data:any) => {
              if (data.estado) {
                this.carreras.splice(this.carreras.findIndex((p:any) => Number(p.id) === Number(data.datos.id)), 1, data.datos);
                this.carreras = [...this.carreras];
                this._AppService.showNotificacionCarrera(`Se asignó correctamente la carrera a la unidad # ${$event.numerounidad}.`);
              }    
            },
           error: (error:any) => {
                if (!error.ok) {
                    this.loadingCarreras = false;
                }
            },
            complete:() => {
                this.loadingCarreras = false;
            }
        }
      ) 
	}

    getDatosCarreraOcupado(carrera:any, unidad:any = {}): any {
        return {
          id: carrera.id,
          clienteid: carrera.clienteid,
          unidadid: unidad.id.toString(),
          nombrecliente: carrera.nombrecliente,
          direcciondestino: carrera.direcciondestino,
          direccionpartida: carrera.direccionpartida,
          observacion: carrera.observacion,
          estadocarrera: 'O',
          usuarioid: carrera.usuarioid,
          ubicacionexactacliente: carrera.ubicacionexactacliente,
          telefonocliente: carrera.telefonocliente,
          boot: carrera.boot ? 'true' : 'false'
        //   estado: data.estado,
        //  id: this.asignacion.id,
       };
     }

    async addCarrera($event:any) {
		this.loadingCarreras = true;
        this.loadingClientes = true
        await this._CarrerasService.addCarrera(this.getDatosNewCarreraCliente($event)).subscribe({        
            next: (data:any) => {
              if (data.estado) {
                this.carreras.unshift(data.datos);
                this.carreras = [...this.carreras];
                this._AppService.showNotificacionCarrera("Carrera creada correctamente.");
              }    
            },
           error: (error:any) => {
                if (!error.ok) {
                    this.loadingCarreras = false;
                    this.loadingClientes = false;
                }
            },
            complete:() => {
                this.loadingCarreras = false;
                this.loadingClientes = false;
            }
        }
      ) 
	}
 
     getDatosNewCarreraCliente(cliente:any):any{
        return {
            clienteid: cliente.id,
            boot:"false",
            direccionpartida: cliente.direccionpartida,
            estadocarrera:'O',
            unidadid:'0',
            usuarioid: this.usuarioLoged.idusuario,
        }
     }

}
