import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthResponse } from '../interfaces/interfaces.interface';
import { JwtService } from 'src/app/service/jwt.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private apiUrl = 'https://your-api-url.com/api/auth';
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private jwtService: JwtService, private router: Router,  ) {}

  login(credentials: { usuario: string, contrasena: string }): Observable<AuthResponse> {
    return this.http.post(`${this.apiUrl}/usuarios/acceder/`, credentials).pipe(
      tap((response:any) => {
        response.estado ? this.jwtService.storeToken(response.token) : '';
        
      })
    );
  }



  isLoggedIn(): boolean {
    return !!this.jwtService.getToken(); // Verificar si hay un token almacenado en sessionStorage
  }

  logout(): void {
    this.jwtService.removeToken(); // Eliminar el token del sessionStorage
    this.router.navigate(['/login']); // Redirigir a la página de inicio de sesión u otra página deseada
  }

}
