import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
@Component({
    moduleId: module.id,
    selector: 'icon-transport-suspension',
    template: `
    <ng-template #template>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="class">
            <path opacity="0.5" d="M12.75 17.75V6.25H16V4.75H8V6.25H11.25V17.75H8V19.25H16V17.75H12.75Z" fill="currentColor"/>
            <path d="M8 6.5V4.5C8 4.03534 8 3.80302 7.96157 3.60982C7.80376 2.81644 7.18356 2.19624 6.39018 2.03843C6.19698 2 5.96466 2 5.5 2C5.03534 2 4.80302 2 4.60982 2.03843C3.81644 2.19624 3.19624 2.81644 3.03843 3.60982C3 3.80302 3 4.03534 3 4.5V6.5C3 6.96466 3 7.19698 3.03843 7.39018C3.19624 8.18356 3.81644 8.80376 4.60982 8.96157C4.80302 9 5.03534 9 5.5 9C5.96466 9 6.19698 9 6.39018 8.96157C7.18356 8.80376 7.80376 8.18356 7.96157 7.39018C8 7.19698 8 6.96466 8 6.5Z" fill="currentColor"/>
            <path d="M21 6.5V4.5C21 4.03534 21 3.80302 20.9616 3.60982C20.8038 2.81644 20.1836 2.19624 19.3902 2.03843C19.197 2 18.9647 2 18.5 2C18.0353 2 17.803 2 17.6098 2.03843C16.8164 2.19624 16.1962 2.81644 16.0384 3.60982C16 3.80302 16 4.03534 16 4.5V6.5C16 6.96466 16 7.19698 16.0384 7.39018C16.1962 8.18356 16.8164 8.80376 17.6098 8.96157C17.803 9 18.0353 9 18.5 9C18.9647 9 19.197 9 19.3902 8.96157C20.1836 8.80376 20.8038 8.18356 20.9616 7.39018C21 7.19698 21 6.96466 21 6.5Z" fill="currentColor"/>
            <path d="M8 19.5V17.5C8 17.0353 8 16.803 7.96157 16.6098C7.80376 15.8164 7.18356 15.1962 6.39018 15.0384C6.19698 15 5.96466 15 5.5 15C5.03534 15 4.80302 15 4.60982 15.0384C3.81644 15.1962 3.19624 15.8164 3.03843 16.6098C3 16.803 3 17.0353 3 17.5V19.5C3 19.9647 3 20.197 3.03843 20.3902C3.19624 21.1836 3.81644 21.8038 4.60982 21.9616C4.80302 22 5.03534 22 5.5 22C5.96466 22 6.19698 22 6.39018 21.9616C7.18356 21.8038 7.80376 21.1836 7.96157 20.3902C8 20.197 8 19.9647 8 19.5Z" fill="currentColor"/>
            <path d="M21 19.5V17.5C21 17.0353 21 16.803 20.9616 16.6098C20.8038 15.8164 20.1836 15.1962 19.3902 15.0384C19.197 15 18.9647 15 18.5 15C18.0353 15 17.803 15 17.6098 15.0384C16.8164 15.1962 16.1962 15.8164 16.0384 16.6098C16 16.803 16 17.0353 16 17.5V19.5C16 19.9647 16 20.197 16.0384 20.3902C16.1962 21.1836 16.8164 21.8038 17.6098 21.9616C17.803 22 18.0353 22 18.5 22C18.9647 22 19.197 22 19.3902 21.9616C20.1836 21.8038 20.8038 21.1836 20.9616 20.3902C21 20.197 21 19.9647 21 19.5Z" fill="currentColor"/>
        </svg>
        </ng-template>
    `,
})
export class IconTransportSuspensionComponent {
    @Input() class: any = '';
    @ViewChild('template', { static: true }) template: any;
    constructor(private viewContainerRef: ViewContainerRef) {}
    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
        this.viewContainerRef.element.nativeElement.remove();
    }
}
