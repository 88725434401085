import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClienteRespuesta } from '../interfaces/interfaces.interface';
import { Cliente } from '../models/cliente.model';

@Injectable()
export class ClientesService {

    url:string = ""
  clientes: any;
    constructor(private _http: HttpClient, public router: Router,) { 
      this.url = environment.apiUrl;
    }
    

      /* CLIENTES */

    getClientes(): Observable<ClienteRespuesta> {
        return this._http.get<ClienteRespuesta>(this.url + `/usuarios/mostrar.usuarios?rolid=5`);
    }

    addCliente(data: Cliente): Observable<ClienteRespuesta> {
        return this._http.post<ClienteRespuesta>(this.url + '/usuarios/crear.usuario', data);
    }
    updateCliente(data: Cliente): Observable<ClienteRespuesta> {
        return this._http.put<ClienteRespuesta>(this.url + `/usuarios/actualizar.usuario`, data);
    }
  
}
