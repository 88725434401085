<div class="z-40 shadow-sm" [ngClass]="{ 'dark text-white-dark': store.semidark && store.menu === 'horizontal'}">
    <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
        <div class="horizontal-logo flex items-center justify-between ltr:mr-2 rtl:ml-2 lg:hidden">
            <!-- <a routerLink="/" class="main-logo flex shrink-0 items-center">
                <img class="inline w-14 ltr:-ml-1 rtl:-mr-1" src="/assets/images/rtsg/logoRTSGTaxi.png" alt="" />
                <span class="hidden align-middle text-2xl font-semibold transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light md:inline">
                    RTSG
                </span>
            </a> -->
            <a routerLink="/" class="main-logo flex shrink-0 items-center">
                <!-- <img class="inline w-14 ltr:-ml-1 rtl:-mr-1" src="/assets/images/rtsg/logoRTSGTaxi.png" alt="" /> -->
                <span class="hidden align-middle text-2xl font-semibold transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light md:inline">
                    RT
                </span>
            </a>

            <a
                href="javascript:;"
                class="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 
                hover:text-primary ltr:ml-2 rtl:mr-2
                dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden"
                (click)="storeData.dispatch({type: 'toggleSidebar'})"
            >
                <icon-menu class="h-5 w-5" />
            </a>
        </div>

        <div class="flex flex-col  ltr:mr-2 rtl:ml-2">
            <span class="hidden items-end align-middle text-xl font-semibold transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light md:inline">
                Bienvenido {{getFirstName(usuarioLoged.nombres_usuario)}}
                <img class="inline mb-1 w-7 ltr:-ml-1 rtl:-mr-1" src="/assets/images/gif/hello.gif" alt="" />
            </span>
            <p class="hidden transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light md:inline">
                {{title_pagina}}
            </p>
        </div>

        <div
            class="flex items-center space-x-1.5 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2"
        >
            <div class="sm:ltr:mr-auto sm:rtl:ml-auto">
              
            </div>
          
            <!-- elejir tema dark o light -->
            <div>
                <a
                    href="javascript:;"
                    *ngIf="store.theme === 'light'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({type: 'toggleTheme', payload:'dark'})"
                >
                    <icon-sun />
                </a>
                <a
                    href="javascript:;"
                    *ngIf="store.theme === 'dark'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({type: 'toggleTheme', payload:'system'})"
                >
                    <icon-moon />
                </a>
                <a
                    href="javascript:;"
                    *ngIf="store.theme === 'system'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({type: 'toggleTheme', payload:'light'})"
                >
                    <icon-laptop />
                </a>
            </div>
             <!-- elejir idioma i18n -->
            <!-- <div class="dropdown shrink-0">
                <div hlMenu>
                    <button
                        type="button"
                        hlMenuButton
                        class="block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    >
                        <img [src]="'/assets/images/flags/'+ store.locale.toUpperCase() + '.svg'" alt="flag" class="h-5 w-5 rounded-full object-cover" />
                    </button>
                    <ul
                        *hlMenuItems
                        @toggleAnimation
                        class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark ltr:-right-14 rtl:-left-14 dark:text-white-dark dark:text-white-light/90 sm:ltr:-right-2 sm:rtl:-left-2"
                    >
                        <li *ngFor="let item of store.languageList;">
                            <button
                                *hlMenuItem="let menuItem"
                                type="button"
                                class="w-full hover:text-primary"
                                [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }"
                                (click)="changeLanguage(item)"
                            >
                                <img class="h-5 w-5 rounded-full object-cover" [src]="'/assets/images/flags/'+ item.code.toUpperCase() + '.svg'" alt="" />
                                <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div> -->
            
            <!-- menu perfil persona -->
         
            <div class="dropdown shrink-0">
                <div hlMenu class="!block">
                    <button type="button" hlMenuButton class="group relative block">
                        <img class="h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100" src="/assets/images/user-profile.jpeg" alt="" />
                    </button>
                    <ul
                        *hlMenuItems
                        @toggleAnimation
                        class="top-11 w-[230px] !py-0 font-semibold text-dark ltr:right-0 rtl:left-0 dark:text-white-dark dark:text-white-light/90"
                    >
                        <li>
                            <div class="flex items-center px-4 py-4">
                                <!-- <div class="flex-none">
                                    <img class="h-10 w-10 rounded-md object-cover" src="/assets/images/user-profile.jpeg" alt="" />
                                </div> -->
                                <div class="truncate ltr:pl-4 rtl:pr-4">
                                    <h4 class="text-base">
                                        {{getFirstName(usuarioLoged.nombres_usuario)}}
                                    </h4>
                                    <a class="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white" href="javascript:;"
                                        >{{usuarioLoged.usuario}}</a
                                    >
                                </div>
                            </div>
                        </li>
                        <li>
                            <a routerLink="/users/profile" *hlMenuItem="let menuItem" class="dark:hover:text-white cursor-pointer">
                                <icon-user class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                Mi perfil
                            </a>
                        </li>
                        <!-- <li>
                            <a routerLink="/apps/mailbox" *hlMenuItem="let menuItem" class="dark:hover:text-white">
                                <icon-mail class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />

                                Inbox
                            </a>
                        </li>
                        <li>
                            <a routerLink="/auth/boxed-lockscreen" *hlMenuItem="let menuItem" class="dark:hover:text-white">
                                <icon-lock-dots class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />

                                Lock Screen
                            </a>
                        </li> -->
                        <li class="border-t border-white-light dark:border-white-light/10">
                            <a (click)="logout()" *hlMenuItem="let menuItem" class="!py-3 text-danger cursor-pointer">
                                <icon-logout class="h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />
                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- horizontal menu -->
    <ul
        class="horizontal-menu hidden border-t border-[#ebedf2] bg-white px-6 py-1.5 font-semibold text-black rtl:space-x-reverse dark:border-[#191e3a] dark:bg-[#0e1726] dark:text-white-dark lg:space-x-1.5 xl:space-x-8"
    >
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-dashboard class="shrink-0" />

                    <span class="px-2">{{ 'dashboard' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ 'sales' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/analytics" routerLinkActive="active">{{ 'analytics' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/finance" routerLinkActive="active">{{ 'finance' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/crypto" routerLinkActive="active">{{ 'crypto' | translate }}</a>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-apps class="shrink-0" />

                    <span class="px-2">{{'apps' | translate}}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/apps/chat" routerLinkActive="active">{{ 'chat' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/apps/mailbox" routerLinkActive="active">{{ 'mailbox' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/apps/todolist" routerLinkActive="active">{{ 'todo_list' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/apps/notes" routerLinkActive="active">{{ 'notes' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/apps/scrumboard" routerLinkActive="active">{{ 'scrumboard' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/apps/contacts" routerLinkActive="active">{{ 'contacts' | translate }}</a>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'invoice' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/apps/invoice/list" routerLinkActive="active">{{ 'list' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/apps/invoice/preview" routerLinkActive="active">{{ 'preview' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/apps/invoice/add" routerLinkActive="active">{{ 'add' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/apps/invoice/edit" routerLinkActive="active">{{ 'edit' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a routerLink="/apps/calendar" routerLinkActive="active">{{ 'calendar' | translate }}</a>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-components class="shrink-0" />
                    <span class="px-2">{{ 'components' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/components/tabs" routerLinkActive="active">{{ 'tabs' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/accordions" routerLinkActive="active">{{ 'accordions' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/modals" routerLinkActive="active">{{ 'modals' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/cards" routerLinkActive="active">{{ 'cards' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/carousel" routerLinkActive="active">{{ 'carousel' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/countdown" routerLinkActive="active">{{ 'countdown' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/counter" routerLinkActive="active">{{ 'counter' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/sweetalert" routerLinkActive="active">{{ 'sweet_alerts' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/timeline" routerLinkActive="active">{{ 'timeline' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/notifications" routerLinkActive="active">{{ 'notifications' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/media-object" routerLinkActive="active">{{ 'media_object' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/list-group" routerLinkActive="active">{{ 'list_group' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/pricing-table" routerLinkActive="active">{{ 'pricing_tables' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/components/lightbox" routerLinkActive="active">{{ 'lightbox' | translate }}</a>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-elements class="shrink-0" />
                    <span class="px-2">{{ 'elements' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/elements/alerts" routerLinkActive="active">{{ 'alerts' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/avatar" routerLinkActive="active">{{ 'avatar' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/badges" routerLinkActive="active">{{ 'badges' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/breadcrumbs" routerLinkActive="active">{{ 'breadcrumbs' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/buttons" routerLinkActive="active">{{ 'buttons' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/buttons-group" routerLinkActive="active">{{ 'button_groups' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/color-library" routerLinkActive="active">{{ 'color_library' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/dropdown" routerLinkActive="active">{{ 'dropdown' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/infobox" routerLinkActive="active">{{ 'infobox' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/jumbotron" routerLinkActive="active">{{ 'jumbotron' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/loader" routerLinkActive="active">{{ 'loader' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/pagination" routerLinkActive="active">{{ 'pagination' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/popovers" routerLinkActive="active">{{ 'popovers' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/progress-bar" routerLinkActive="active">{{ 'progress_bar' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/search" routerLinkActive="active">{{ 'search' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/tooltips" routerLinkActive="active">{{ 'tooltips' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/treeview" routerLinkActive="active">{{ 'treeview' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/elements/typography" routerLinkActive="active">{{ 'typography' | translate }}</a>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-datatables class="shrink-0" />
                    <span class="px-2">{{ 'tables' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/tables" routerLinkActive="active">{{ 'tables' | translate }}</a>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'datatables' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/datatables/basic" routerLinkActive="active">{{ 'basic' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/advanced" routerLinkActive="active">{{ 'advanced' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/skin" routerLinkActive="active">{{ 'skin' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/order-sorting" routerLinkActive="active">{{ 'order_sorting' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/columns-filter" routerLinkActive="active">{{ 'columns_filter' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/multi-column" routerLinkActive="active">{{ 'multi_column' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/multiple-tables" routerLinkActive="active">{{ 'multiple_tables' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/alt-pagination" routerLinkActive="active">{{ 'alt_pagination' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/checkbox" routerLinkActive="active">{{ 'checkbox' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/range-search" routerLinkActive="active">{{ 'range_search' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/export" routerLinkActive="active">{{ 'export' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/sticky-header" routerLinkActive="active">{{ 'sticky_header' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/clone-header" routerLinkActive="active">{{ 'clone_header' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/datatables/column-chooser" routerLinkActive="active">{{ 'column_chooser' | translate }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-forms class="shrink-0" />
                    <span class="px-2">{{ 'forms' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/forms/basic" routerLinkActive="active">{{ 'basic' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/input-group" routerLinkActive="active">{{ 'input_group' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/layouts" routerLinkActive="active">{{ 'layouts' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/validation" routerLinkActive="active">{{ 'validation' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/input-mask" routerLinkActive="active">{{ 'input_mask' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/select2" routerLinkActive="active">{{ 'select2' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/touchspin" routerLinkActive="active">{{ 'touchspin' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/checkbox-radio" routerLinkActive="active">{{ 'checkbox_and_radio' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/switches" routerLinkActive="active">{{ 'switches' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/wizards" routerLinkActive="active">{{ 'wizards' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/file-upload" routerLinkActive="active">{{ 'file_upload' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/quill-editor" routerLinkActive="active">{{ 'quill_editor' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/markdown-editor" routerLinkActive="active">{{ 'markdown_editor' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/date-picker" routerLinkActive="active">{{ 'date_and_range_picker' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/forms/clipboard" routerLinkActive="active">{{ 'clipboard' | translate }}</a>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-pages class="shrink-0" />
                    <span class="px-2">{{ 'pages' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'users' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/users/profile" routerLinkActive="active">{{ 'profile' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/users/user-account-settings" routerLinkActive="active">{{ 'account_settings' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a routerLink="/pages/knowledge-base" routerLinkActive="active">{{ 'knowledge_base' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/pages/contact-us-boxed" target="_blank" routerLinkActive="active">{{ 'contact_us_boxed' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/pages/contact-us-cover" target="_blank" routerLinkActive="active">{{ 'contact_us_cover' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/pages/faq" routerLinkActive="active">{{ 'faq' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/pages/coming-soon-boxed" target="_blank" routerLinkActive="active">{{ 'coming_soon_boxed' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/pages/coming-soon-cover" target="_blank" routerLinkActive="active">{{ 'coming_soon_cover' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/pages/maintenence" target="_blank" routerLinkActive="active">{{ 'maintenence' | translate }}</a>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'error' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/pages/error404" target="_blank" routerLinkActive="active">{{ '404' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/pages/error500" target="_blank" routerLinkActive="active">{{ '500' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/pages/error503" target="_blank" routerLinkActive="active">{{ '503' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'login' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/auth/cover-login" target="_blank" routerLinkActive="active">{{ 'login_cover' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/auth/boxed-signin" target="_blank" routerLinkActive="active">{{ 'login_boxed' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'register' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/auth/cover-register" target="_blank" routerLinkActive="active">{{ 'register_cover' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/auth/boxed-signup" target="_blank" routerLinkActive="active">{{ 'register_boxed' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'password_recovery' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/auth/cover-password-reset" target="_blank" routerLinkActive="active">{{ 'recover_id_cover' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/auth/boxed-password-reset" target="_blank" routerLinkActive="active">{{ 'recover_id_boxed' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li class="relative">
                    <a href="javascript:;"
                        >{{ 'lockscreen' | translate }}
                        <div class="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                            <icon-caret-down />
                        </div>
                    </a>
                    <ul
                        class="absolute top-0 z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow ltr:left-[95%] rtl:right-[95%] dark:bg-[#1b2e4b] dark:text-white-dark"
                    >
                        <li>
                            <a routerLink="/auth/cover-lockscreen" target="_blank" routerLinkActive="active">{{ 'unlock_cover' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/auth/boxed-lockscreen" target="_blank" routerLinkActive="active">{{ 'unlock_boxed' | translate }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li class="menu nav-item relative">
            <a href="javascript:;" class="nav-link">
                <div class="flex items-center">
                    <icon-menu-more class="shrink-0" />

                    <span class="px-2">{{ 'more' | translate }}</span>
                </div>
                <div class="right_arrow">
                    <icon-caret-down />
                </div>
            </a>
            <ul class="sub-menu">
                <li>
                    <a routerLink="/dragndrop" routerLinkActive="active">{{ 'drag_and_drop' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/charts" routerLinkActive="active">{{ 'charts' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/font-icons" routerLinkActive="active">{{ 'font_icons' | translate }}</a>
                </li>
                <li>
                    <a routerLink="/widgets" routerLinkActive="active">{{ 'widgets' | translate }}</a>
                </li>
                <li>
                    <a href="https://vristo.sbthemes.com" target="_blank" routerLinkActive="active">{{ 'documentation' | translate }}</a>
                </li>
            </ul>
        </li>
    </ul>
</div>
