<div [ngClass]="{ 'dark text-white-dark': store.semidark }">
    <nav class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
        <div class="h-full bg-white dark:bg-[#0e1726]">
            <div class="flex items-center justify-between px-4 py-3">
                <!-- <a routerLink="/" class="main-logo flex shrink-0 items-center">
                    <img class="ml-[5px] w-14 flex-none" src="/assets/images/rtsg/logoRTSGTaxi.png" alt="" />
                    <span class="align-middle text-2xl font-semibold ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light lg:inline">RTSG</span>
                </a> -->
                <a routerLink="/" class="main-logo flex shrink-0 items-center">
                    <!-- <img class="ml-[5px] w-14 flex-none" src="/assets/images/rtsg/logoRTSGTaxi.png" alt="" /> -->
                    <span class="align-middle text-2xl font-semibold ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light lg:inline">RT</span>
                </a>
                <a
                    href="javascript:;"
                    class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
                    (click)="storeData.dispatch({ type: 'toggleSidebar'})"
                >
                    <icon-carets-down class="m-auto rotate-90" />
                </a>
            </div>
            <ng-scrollbar class="relative !h-[calc(100vh-80px)]" appearance="compact">
                <ul class="relative space-y-0.5 p-4 py-0 font-semibold">

                    <li class="nav-item">
                        <a routerLink="dashboard" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-menu-dashboard class="shrink-0 group-hover:!text-primary" />
                                <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Dashboard</span>
                            </div>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="carreras" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                            <div class="flex items-center">
                                <icon-wheel class="shrink-0 group-hover:!text-primary" />
                                <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Carreras</span>
                            </div>
                        </a>
                    </li>

                    <h2 class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                        <icon-minus class="hidden h-5 w-4 flex-none" />
                        <span>Gestión</span>
                        <!-- <span>{{ ('gestion' | translate) || '&nbsp;' }}</span> -->
                    </h2>

                    <li class="nav-item">
                        <ul>
                            <li class="nav-item">
                                <a routerLink="clientes" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-users class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Clientes</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="socios" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-users-id class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Socios</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="unidades" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-transport-suspension class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Unidades</span>
                                    </div>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="monitores" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-phone-call class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Monitores</span>
                                    </div>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="conductores" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-transmision class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Conductores</span>
                                    </div>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="asignaciones" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-arrow-reorder class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Asignaciones</span>
                                    </div>
                                </a>
                            </li>
                      
                        </ul>
                    </li>

                    <h2 class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                        <icon-minus class="hidden h-5 w-4 flex-none" />
                        <span>Configuración</span>
                        <!-- <span>{{ ('gestion' | translate) || '&nbsp;' }}</span> -->
                    </h2>

                    <li class="nav-item">
                        <ul>
                            <li class="nav-item">
                                <a routerLink="personas" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-users class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Personas</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="bases" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-users-id class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Bases</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="mi_empresa" class="group" routerLinkActive="active" (click)="toggleMobileMenu()">
                                    <div class="flex items-center">
                                        <icon-infraestructure class="shrink-0 group-hover:!text-primary" />
                                        <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Mi empresa</span>
                                    </div>
                                </a>
                            </li>

                           
                      
                        </ul>
                    </li>
                 
                </ul>
            </ng-scrollbar>
        </div>
    </nav>
</div>
