import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../features/auth/login/services/auth.service';
import { JwtService } from '../service/jwt.service';
import { AppService } from '../service/app.service';

@Injectable()
export class HttpRequestLoggerInterceptor implements HttpInterceptor {

  constructor(private router: Router, private jwtService: JwtService, private authService: AuthService, private _AppService: AppService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const clonedReq = request.clone({
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-access-token':`${this.jwtService.getToken()}`
      })
  });
  return next.handle(clonedReq).pipe(
      tap(
          (event : HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                // console.log(this.jwtService.getToken());
                
                // if(!this.jwtService.getToken()){
                //   this._AppService.showNotificacion(`Ocurrió un error inesperado, contáctese con el administrador del sistema.`, 'error');
                //   this.authService.logout();
                // }
                // console.log(this.jwtService.getDecodedToken());
                
                      //Manejar la respuesta
                      // console.log('Respuesta exitosa recibida:', event);
                      // console.log('Cuerpo de la respuesta:', event.body);
                      // console.log('Encabezados:', event.headers.keys());
                      
                }
          },
          (error : HttpErrorResponse ) => {
              if (error.status == 401) {
                this.authService.logout();
                  // this.router.navigate(['/auth/login'])
              }
          }
      )
  )
  }
}